<template>
    <div :class="isMobile ? 'cd_header_mobile' : 'cd_header'">
        <view class="box">
            <div class="language" v-if="isShowLan && !isMobile">
                <div class="item" :class="languageIndex === index ? 'item_sel' : ''"
                    v-for="(item, index) in languageList" :key="index" @click="onSwitchLanguage(index)">{{ item }}</div>
            </div>
            <div class="language" v-else></div>
            <cd-nav ref="cdNavRef" @onExpand="onExpand"></cd-nav>
        </view>
        <cd-nav-h5 ref="cdNavH5Ref"></cd-nav-h5>
    </div>
</template>

<script lang="ts" setup>
// vue
import { reactive, ref, watch } from "vue";
// 组件
import CdNav from "../components/CdNav/index.vue";
import CdNavH5 from "../components/CdNavH5/index.vue";
// 状态值
import { common } from "@/store/modules/common";
import { useRouter } from "vue-router";
// ============================== 定义字段开始 ============================== //
// const languageList = reactive([ "En", "RI","中"]);
const languageList = reactive(["En", "RI", "中"]);
let languageIndex = ref<Number>(0)
languageIndex.value = localStorage.getItem('languageIndex') ? Number(localStorage.getItem('languageIndex')) : 0
// 是否移动端
let isMobile = ref<Boolean>(false);
let zoom = ref<any>(1);
// 定义ref
const cdNavRef: any = ref(null);
const cdNavH5Ref: any = ref(null);
const router = useRouter()
const isShowLan = ref<Boolean>(true)
// ============================== 定义字段结束 ============================== //

// ============================== 事件开始 ============================== //
// 切换语言
const onSwitchLanguage = (index: Number) => {
    languageIndex.value = index;
    localStorage.setItem('languageIndex', JSON.stringify(index))
    switch (index) {
        case 0:
            localStorage.setItem('locale', 'en')
            break;
        case 1:
            localStorage.setItem('locale', 'ri')
            break;
        case 2:
            localStorage.setItem('locale', 'zh')
            break;
    }
    window.location.reload()
};
// 打开菜单
const onExpand = () => {
    cdNavH5Ref.value.open();
};
// ============================== 事件结束 ============================== //

// ============================== 监听开始 ============================== //
watch(
    () => [common().isMobile, common().zoom],
    ([newIsMobile, newZoom]) => {
        isMobile.value = newIsMobile;
        zoom.value = newZoom;
    },
    { immediate: true }
);
watch(
    () => router.currentRoute.value,
    (newVal: any, oldVal: any) => {
        if (newVal.path == "/news/detail") {
            isShowLan.value = false
        } else {
            isShowLan.value = true
        }
    },
    { immediate: true }
);
// ============================== 监听结束 ============================== //
</script>

<style scoped lang="scss">
.cd_header {
    // width: 100%;
    height: 146px;
    background: #ffffff;
    display: flex;
    justify-content: center;

    .box {
        width: 1226px !important;
        padding-top: 50px;
        position: relative;

        .language {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: 0;
            top: 80px;

            .item {
                width: 28px;
                height: 22px;
                border: 1px solid #7a7a7a;
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #7a7a7a;
                border-right: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:last-child {
                    border: 1px solid #7a7a7a;
                }
            }

            .item_sel {
                color: #ffffff;
                background-color: #7a7a7a;
            }
        }
    }
}
</style>
