import { defineStore } from "pinia";
import { ref, onMounted, onUnmounted, watch } from "vue";
// 自定义方法
import { onIsMobile } from "@/utils/common";

export const common = defineStore("common", () => {
  // 移动端的比例 zoom = window.innerWidth/750
  // 判断是否移动端
  const zoom = ref<any>(1);
  const isMobile = ref<Boolean>(false);
  isMobile.value = Boolean(onIsMobile() ? "true" : "");
  function handleResize() {
    zoom.value = window.innerWidth > 750 ? 1 : window.innerWidth / 750;
    setTimeout(function () {
      if (isMobile.value !== Boolean(onIsMobile() ? "true" : "")) {
        window.location.reload();
      }
      isMobile.value = Boolean(onIsMobile() ? "true" : "");
    }, 50);
  }
  handleResize();

  const navIndex = ref<any>(0);
  const navChildIndex = ref<any>(0);
  navIndex.value = localStorage.getItem("navIndex");
  navChildIndex.value = localStorage.getItem("navChildIndex");
  const setNavIndex = (index: any) => {
    navIndex.value = index;
  };
  const setNavChildIndex = (index: any) => {
    navChildIndex.value = index;
  };

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  return {
    isMobile,
    zoom,
    navIndex,
    navChildIndex,
    setNavIndex,
    setNavChildIndex,
  };
});
