<template>
  <!-- <div :class="isMobile ? 'cd_footer_mobile' : 'cd_footer'"> -->
  <div
    :class="{
      cd_footer_mobile: isMobile,
      cd_footer_zh: !isMobile,
      cd_footer_en: !isMobile && languageIndex === 0,
      cd_footer_ri: !isMobile && languageIndex === 1,
    }"
  >
    <div class="box">
      <div class="top">
        <img
          class="logo"
          src="@/assets/images/pc/home/logo.png"
          alt="logo"
          v-if="!isMobile"
        />
        <div class="software">
          <div class="title">{{ t("socialMedia") }}</div>
          <div class="software_box" v-if="!isMobile">
            <div
              class="item"
              v-for="(item, index) in softwareList"
              :key="index"
              @mouseenter="onMouseEnter(index)"
              @mouseleave="onMouseLeave"
            >
              <img
                class="image"
                :src="
                  softwareIndex === index && isShow
                    ? item.brightUrl
                    : item.grayUrl
                "
                @click="onApps(item.appUrl)"
              />
              <div
                class="QRCode"
                v-if="softwareIndex === index && isShow && !isMobile"
              >
                <img
                  class="QRCode_img"
                  :src="item.QRCode2"
                  v-if="item.QRCode2"
                />
                <img class="QRCode_img" :src="item.QRCode" />
              </div>
            </div>
          </div>
          <div class="software_box" v-else>
            <div
              class="item"
              v-for="(item, index) in softwareList"
              :key="index"
            >
              <img
                class="image"
                :src="item.brightMobileUrl"
                @click="onApps(item.appUrl)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="list">
          <div
            class="item"
            :class="`item${index}`"
            v-for="(item, index) in msgList"
            :key="index"
          >
            <div class="title">{{ item.title }}</div>
            <div class="msg">
              <div
                class="msg_item"
                v-for="(itemTwo, indexTwo) in item.list"
                :key="indexTwo"
              >
                <div class="one" @click="onPath(itemTwo)">
                  {{ itemTwo.one }}
                </div>
                <div class="two" v-if="itemTwo.two">{{ itemTwo.two }}</div>
                <div class="two" v-if="itemTwo.three">
                  {{ itemTwo.three }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// vue
import { reactive, ref, watch } from "vue";
// 状态值
import { common } from "@/store/modules/common";
// ============================== 国际化开始 ============================== //
// 国际化
import { useI18n } from "vue-i18n";
// 导入翻译文件
import enMessages from "@/i18n/en.json";
import zhMessages from "@/i18n/zh.json";
import riMessages from "@/i18n/ri.json";
import { useRouter } from "vue-router";

// 创建国际化实例
const { t, locale } = useI18n({
  locale: "zh", // 设置默认语言
  messages: {
    en: enMessages,
    zh: zhMessages,
    ri: riMessages,
  },
});
const languageIndex = ref<any>(0);
watch(
  () => localStorage.getItem("languageIndex"),
  (newVal: any, oldVal: any) => {
    languageIndex.value = Number(newVal);
    locale.value = newVal == 2 ? "zh" : newVal == 1 ? "ri" : "en";
  },
  { immediate: true }
);
// ============================== 国际化开始 ============================== //

// ============================== 定义字段开始 ============================== //
const router = useRouter();
let isMobile = ref<Boolean>(false); //是否移动端
const softwareIndex = ref<Number>(0); //软件下标
const isShow = ref<Boolean>(false); //软件是否高亮
const emit = defineEmits<{
  // 打开菜单
  (event: "onFooter"): void;
}>();
// 软件列表
const softwareList = reactive([
  {
    appUrl: "https://www.tiktok.com/@vividaquaindonesia",
    grayUrl: require("@/assets/images/pc/home/software/tiktok-gray.png"),
    brightUrl: require("@/assets/images/pc/home/software/tiktok-bright.png"),
    grayMobileUrl: require("@/assets/images/mobile/home/software/tiktok-gray.png"),
    brightMobileUrl: require("@/assets/images/mobile/home/software/tiktok-bright.png"),
    QRCode: require("@/assets/images/pc/home/software/QRCode/tiktok.svg"),
    QRCode2: "",
  },
  {
    appUrl: "https://x.com/vividaquaindo/status/1791805465145561567?s=46",
    grayUrl: require("@/assets/images/pc/home/software/X-gray.png"),
    brightUrl: require("@/assets/images/pc/home/software/X-bright.png"),
    grayMobileUrl: require("@/assets/images/mobile/home/software/X-gray.png"),
    brightMobileUrl: require("@/assets/images/mobile/home/software/X-bright.png"),
    QRCode: require("@/assets/images/pc/home/software/QRCode/X.svg"),
    QRCode2: "",
  },
  {
    appUrl: "https://youtube.com/shorts/wUSsBRarUGE?si=HCD_NMn9E7FEyQO7",
    grayUrl: require("@/assets/images/pc/home/software/yutube-gray.png"),
    brightUrl: require("@/assets/images/pc/home/software/yutube-bright.png"),
    grayMobileUrl: require("@/assets/images/mobile/home/software/yutube-gray.png"),
    brightMobileUrl: require("@/assets/images/mobile/home/software/yutube-bright.png"),
    QRCode: require("@/assets/images/pc/home/software/QRCode/yutube.svg"),
    QRCode2: "https://youtube.com/shorts/wUSsBRarUGE?si=HCD_NMn9E7FEyQO7",
  },
  {
    appUrl: "https://www.instagram.com/reel/C7G1SUnLQhk/?igsh=OTZ0aWQyNnlwOWpm",
    grayUrl: require("@/assets/images/pc/home/software/ins-gray.png"),
    brightUrl: require("@/assets/images/pc/home/software/ins-bright.png"),
    grayMobileUrl: require("@/assets/images/mobile/home/software/ins-gray.png"),
    brightMobileUrl: require("@/assets/images/mobile/home/software/ins-bright.png"),
    QRCode: require("@/assets/images/pc/home/software/QRCode/ins.svg"),
    QRCode2: "",
  },
  {
    appUrl: "https://www.facebook.com/share/bv1nXKkg8znK9Xxb/?mibextid=WC7FNe",
    grayUrl: require("@/assets/images/pc/home/software/facebook-gray.png"),
    brightUrl: require("@/assets/images/pc/home/software/facebook-bright.png"),
    grayMobileUrl: require("@/assets/images/mobile/home/software/facebook-gray.png"),
    brightMobileUrl: require("@/assets/images/mobile/home/software/facebook-bright.png"),
    QRCode: require("@/assets/images/pc/home/software/QRCode/facebook.svg"),
    QRCode2: "",
  },
  {
    appUrl: "",
    grayUrl: require("@/assets/images/pc/home/software/whatsapp-gray.png"),
    brightUrl: require("@/assets/images/pc/home/software/whatsapp-bright.png"),
    grayMobileUrl: require("@/assets/images/mobile/home/software/whatsapp-gray.png"),
    brightMobileUrl: require("@/assets/images/mobile/home/software/whatsapp-bright.png"),
    QRCode: require("@/assets/images/pc/home/software/QRCode/whatsapp1.svg"),
    QRCode2: require("@/assets/images/pc/home/software/QRCode/whatsapp2.svg"),
  },
]);
const msgOriginalList = reactive([
  {
    title: t("productPresent"),
    list: [
      {
        one: t("productPresent1"),
        two: "",
        pathName: "Products",
        navIndex: 1,
        navChildIndex: 0,
      },
      {
        one: t("productPresent2"),
        two: "",
        pathName: "Products",
        navIndex: 1,
        navChildIndex: 1,
      },
      {
        one: t("productPresent3"),
        two: "",
        pathName: "Products",
        navIndex: 1,
        navChildIndex: 2,
      },
    ],
  },
  {
    title: t("aboutUs"),
    list: [
      {
        one: t("aboutUs1"),
        two: "",
        pathName: "About",
        navIndex: 2,
        navChildIndex: 0,
      },
      {
        one: t("aboutUs2"),
        two: "",
        pathName: "About",
        navIndex: 2,
        navChildIndex: 0,
      },
      {
        one: t("aboutUs3"),
        two: "",
        pathName: "About",
        navIndex: 2,
        navChildIndex: 1,
      },
      {
        one: t("aboutUs4"),
        two: "",
        pathName: "About",
        navIndex: 2,
        navChildIndex: 2,
      },
      {
        one: t("aboutUs5"),
        two: "",
        pathName: "About",
        navIndex: 2,
        navChildIndex: 3,
      },
      {
        one: t("aboutUs6"),
        two: "",
        pathName: "About",
        navIndex: 2,
        navChildIndex: 4,
      },
    ],
  },
  {
    title: t("adress"),
    list: [
      {
        one: t("adressTitle1"),
        two: t("address1"),
        three: t("address5"),
        pathName: "",
        navChildIndex: "",
      },
      {
        one: t("adressTitle2"),
        two: t("address2"),
        three: t("address6"),
        pathName: "",
        navChildIndex: "",
      },
      {
        one: t("adressTitle3"),
        two: t("address3"),
        three: t("address7"),
        pathName: "",
        navChildIndex: "",
      },
      {
        one: t("adressTitle4"),
        two: t("address4"),
        three: t("address8"),
        pathName: "",
        navChildIndex: "",
      },
    ],
  },
  {
    title: t("contactDetails"),
    list: [
      {
        one: "0811-1000-4000",
        two: "",
        pathName: "",
        navChildIndex: "",
      },
      {
        one: "vividaqua.id@gmail.com",
        two: "",
        pathName: "",
        navChildIndex: "",
      },
      {
        one: "info@vividaqua-id.com",
        two: "",
        pathName: "",
        navChildIndex: "",
      },
    ],
  },
]);
let msgList = reactive(new Array());
// ============================== 定义字段结束 ============================== //

// ============================== 事件开始 ============================== //
// 鼠标移入
const onMouseEnter = (index: Number) => {
  isShow.value = true;
  softwareIndex.value = index;
};
// 鼠标离开
const onMouseLeave = () => {
  isShow.value = false;
};
// 跳转APP网站
const onApps = (appUrl: any) => {
  window.open(appUrl, "_blank");
};
// 跳转
const onPath = (val: any) => {
  localStorage.setItem("navIndex", JSON.stringify(val.navIndex));
  localStorage.setItem("navChildIndex", JSON.stringify(val.navChildIndex));
  common().setNavIndex(val.navIndex);
  common().setNavChildIndex(val.navChildIndex);
  router.push({ name: val.pathName });
  emit("onFooter");
};
// ============================== 事件结束 ============================== //

// ============================== 监听开始 ============================== //
watch(
  () => [common().isMobile],
  ([newIsMobile]) => {
    if (newIsMobile) {
      msgList[0] = { ...msgOriginalList[2] };
      msgList[1] = { ...msgOriginalList[3] };
    } else {
      msgList = msgOriginalList;
    }
    isMobile.value = newIsMobile;
  },
  { immediate: true }
);
// ============================== 监听结束 ============================== //
</script>

<style scoped lang="scss">
.cd_footer_zh {
  margin: 0 auto;
  width: 1226px;
  background: #ececec;
  position: relative;

  .box {
    width: 1006px;
    padding-top: 53px;
    padding-left: 220px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 46px;
      border-bottom: 1px solid #d9d9d9;

      .logo {
        width: 157px;
        height: 30px;
      }

      .software {
        display: flex;
        align-items: center;

        .title {
          font-family: SourceHanSansCN-Regular;

          font-size: 16px;
          color: #595757;
          margin-right: 50px;
        }

        .software_box {
          display: flex;
          align-items: center;
          padding-right: 25px;

          .item {
            width: 28px;
            height: 28px;
            margin-right: 3px;
            position: relative;

            &:last-child {
              margin-right: 0;
            }

            .image {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }

            .QRCode {
              height: 150px;
              position: absolute;
              top: -164px;
              right: 0;
              display: flex;

              .QRCode_img {
                width: 150px;
                height: 150px;

                &:nth-child(2) {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 32px;
      padding-bottom: 32px;
      .list {
        display: flex;

        .item {
          margin-right: 39px;

          .title {
            font-family: SourceHanSansCN-Regular;

            font-size: 16px;
            color: #222222;
          }

          .msg {
            .msg_item {
              width: 155px;

              &:first-child {
                margin-top: 5px;
              }

              .one {
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #595757;
                line-height: 20px;
                padding: 6px 0;
              }

              .two {
                font-family: SourceHanSansCN-Regular;

                font-size: 12px;
                color: #888888;
                line-height: 16px;
              }
            }
          }

          &:nth-child(1) {
            .msg {
              .msg_item {
                width: 160px;

                .one {
                  cursor: pointer;
                }
              }
            }
          }

          &:nth-child(2) {
            .msg {
              .msg_item {
                width: 138px;

                .one {
                  cursor: pointer;
                }
              }
            }
          }

          &:nth-child(3) {
            margin-right: 30px;

            .msg_item {
              width: 414px;
            }
          }

          &:nth-child(4) {
            margin-right: 0;

            .msg_item {
              width: 166px;
            }
          }
        }
      }
    }
  }

  &::before {
    content: "";
    width: 10px;
    background: #158bda;
    position: absolute;
    left: 0;
    top: 53px;
    bottom: 53px;
  }
}

.cd_footer_en,
.cd_footer_ri {
  .box {
    width: 1176px;
    padding-left: 30px;

    .top {
      .software {
        .software_box {
          padding-right: 0;
        }
      }
    }

    .bottom {
      .list {
        .item {
          margin-right: 20px;

          &:nth-child(1) {
            margin-right: 15px;

            .msg {
              .msg_item {
                width: 257px;
              }
            }
          }

          &:nth-child(2) {
            .msg {
              .msg_item {
                width: 300px;
              }
            }
          }

          &:nth-child(3) {
            .msg {
              .msg_item {
                width: 390px;
              }
            }
          }

          &:nth-child(4) {
            margin-right: 0;

            .msg {
              .msg_item {
                width: 151px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
