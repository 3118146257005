import { createApp } from "vue";
import { createI18n } from "vue-i18n"; //国际化
import App from "./App.vue";
import router from "./router";
import store from "@/store";

// 状态值
import { common } from "@/store/modules/common";
// 自定义方法
import { getStorage } from "./utils/storage";
// 国际化语言
import enMessages from "./i18n/en.json";
import zhMessages from "./i18n/zh.json";
import riMessages from "./i18n/ri.json";
const i18n = createI18n({
  locale: getStorage("locale") || "zh", // 设置默认语言
  messages: {
    en: enMessages,
    zh: zhMessages,
    ri: zhMessages,
  },
});

// 引入Element Plus
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import enCn from "element-plus/es/locale/lang/en";
import idCn from "element-plus/es/locale/lang/id";
import "element-plus/dist/index.css";

// 引入Vant
// import Vant from "vant";
import "vant/lib/index.css";

// 自定义样式
import "@/styles/init.scss";
import "@/styles/common.scss";
import "@/styles/mobile.scss";
// 第三方字体：思源黑体
import "./styles/font/font.scss";

const languageIndex = Number(localStorage.getItem("languageIndex"));
const app = createApp(App);

// 使用国际化语言
app.use(i18n);
// app.use(Vant);

app.use(ElementPlus, {
  locale: languageIndex === 2 ? zhCn : languageIndex === 1 ? idCn : enCn,
});
// 挂在pinia
app.use(store);

app.use(router).mount("#app");
