<template>
    <el-drawer class="cd-nav-h5" custom-class="cd-nav-h5" v-model="drawer" title="I am the title" direction="rtl"
        size="100%" :with-header="false" destroy-on-close>
        <div class="header">
            <img class="logo" src="@/assets/images/mobile/home/logo.png">
            <img class="close" src="@/assets/images/mobile/home/close.png" @click="close">
        </div>
        <div class="body">
            <div class="item" :class="navIndex === index ? 'item_sel' : ''" v-for="(item, index) in navList"
                :key="index">
                <div class="title" @click="onSwitchNav(index)">
                    <div class="msg">{{ item.title }}</div>
                    <img class="title_icon" src="@/assets/images/mobile/home/down.png" v-if="item.children.length">
                </div>
                <div class="children" :style="{ 'height': `${item.isShow ? item.height : '0px'}` }">
                    <div class="itemTwo" :class="navIndex === index && navChildIndex === indexTwo ? 'itemTwo_sel' : ''"
                        v-for="(itemTwo, indexTwo) in item.children" :key="indexTwo"
                        @click="onSwitchNavChild(index, indexTwo)">{{ itemTwo }}</div>
                </div>
            </div>
        </div>
    </el-drawer>
</template>
<script lang="ts" setup>
// vue
import { reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
// 状态值
import { common } from "@/store/modules/common";
// ============================== 国际化开始 ============================== //
// 国际化
import { useI18n } from 'vue-i18n';
// 导入翻译文件
import enMessages from '@/i18n/en.json';
import zhMessages from '@/i18n/zh.json';
import riMessages from '@/i18n/ri.json';

// 创建国际化实例
const { t, locale } = useI18n({
    locale: "zh", // 设置默认语言
    messages: {
        en: enMessages,
        zh: zhMessages,
        ri: riMessages,
    },
});
const languageIndex = ref<any>(0)
watch(
    () => localStorage.getItem('languageIndex'),
    (newVal: any, oldVal: any) => {
        languageIndex.value = Number(newVal)
        locale.value = newVal == 2 ? 'zh' : newVal == 1 ? 'ri' : 'en'
    },
    { immediate: true }
);
// ============================== 国际化开始 ============================== //
// ============================== 控制抽屉开始 ============================== //
const drawer = ref<Boolean>(false); //抽屉
const open = () => {
    drawer.value = true;
};
const close = () => {
    drawer.value = false;
};
// ============================== 控制抽屉结束 ============================== //

// ============================== 定义路由开始 ============================== //
const router = useRouter();
// 跳转路由
const onRouterPath = (index: Number, indexTwo: any) => {
    switch (index) {
        case 0:
            router.push({ name: "Home" });
            break;
        case 1:
            router.push({ name: "Products" });
            break;
        case 2:
            router.push({ name: "About", });
            break;
        case 3:
            router.push({ name: "News", });
            break;
        case 4:
            router.push({ name: "Advisory" });
            break;
    }
    close();
};

// ============================== 定义路由结束 ============================== //

// ============================== 定义字段开始 ============================== //
// nav
let navIndex = ref<Number>(0);
let navChildIndex = ref<Number>(0);
const navList = reactive<any>([
    {
        title: t('nav1'),
        children: [],
        height: "0px",
        isShow: false,
    },
    {
        title: t('nav2'),
        children: [t('productPresent1'), t('productPresent2'), t('productPresent3')],
        height: languageIndex.value === 2 ? "320px" : languageIndex.value === 1 ? "310px" : "360px",
        isShow: false,
    },
    {
        title: t('nav3'),
        children: [t('aboutNav1'), t('aboutNav2'), t('aboutNav3'), t('aboutNav4'), t('aboutNav5')],
        height: languageIndex.value === 2 ? "510px" : languageIndex.value === 1 ? "520px" : "560px",
        isShow: false,
    },
    {
        title: t('nav4'),
        children: [],
        height: "0px",
        isShow: false,
    },
    {
        title: t('nav5'),
        children: [t('advisoryNav1'), t('advisoryNav2')],
        height: "250px",
        isShow: false,
    },
]);
if (localStorage.getItem("navIndex")) {
    navIndex.value = Number(localStorage.getItem("navIndex"));
    navChildIndex.value = Number(localStorage.getItem("navChildIndex"));
}
// ============================== 定义字段结束 ============================== //

// ============================== 事件开始 ============================== //
const onSwitchNav = (index: any) => {
    if (navList[index].children.length) {
        navList[index].isShow = !navList[index].isShow;
        return;
    }
    localStorage.setItem("navIndex", JSON.stringify(index));
    navIndex.value = index;
    onRouterPath(index, null);
};
const onSwitchNavChild = (index: any, indexTwo: any) => {
    localStorage.setItem("navIndex", JSON.stringify(index));
    localStorage.setItem("navChildIndex", JSON.stringify(indexTwo));
    common().setNavIndex(index)
    common().setNavChildIndex(indexTwo)
    navIndex.value = index;
    navChildIndex.value = index;
    onRouterPath(index, indexTwo);
};
// ============================== 事件结束 ============================== //

// ============================== 监听开始 ============================== //
watch(
    () => [drawer.value],
    ([newDrawer]) => {
        navIndex.value = localStorage.getItem("navIndex")
            ? Number(localStorage.getItem("navIndex"))
            : 0;
        navChildIndex.value = localStorage.getItem("navChildIndex")
            ? Number(localStorage.getItem("navChildIndex"))
            : 0;
    },
    { immediate: true }
);
// ============================== 监听结束 ============================== //

// =========================== 暴露函数开始 =========================== //
defineExpose({
    open,
});
// =========================== 暴露函数结束 =========================== //
</script>
<style lang="scss" scoped>
.cd-nav-h5 {
    .header {
        padding: 50px 30px 45px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;

        .logo {
            width: 250px;
            height: 47.73px;
        }

        .close {
            width: 32px;
            height: 32px;
        }
    }

    .body {
        padding: 43px 40px 0 40px;

        .item {
            margin-bottom: 50px;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .msg {
                    font-family: SourceHanSansCN-Regular;

                    font-size: 34px;
                    color: #555555;
                }

                .title_icon {
                    width: 24px;
                    height: 13.55px;
                }
            }

            .children {
                padding-left: 70px;
                transition: all 0.3s;
                overflow: hidden;

                .itemTwo {
                    font-family: SourceHanSansCN-Regular;

                    font-size: 34px;
                    color: #555555;
                    margin-top: 50px;
                }

                .itemTwo_sel {
                    color: #0050ea;
                }
            }
        }

        .item_sel {
            .title {
                .msg {
                    color: #0050ea;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.cd-nav-h5 {
    .el-drawer__body {
        padding: 0 !important;
    }
}
</style>