<template>
    <el-scrollbar ref="elScrollbarRef" native>
        <div :class="isMobile ? 'layout_mobile' : 'layout'" ref="layoutRef" :style="{
            // 'zoom': zoom,
            'transform': `scale(${zoom})`,
            'transform-origin': 'left top'
            // 'font-size': `${(zoom * (1 / zoom)) * (1 / (zoom * (1 / zoom))) * (zoom * (1 / zoom))}rem !important`,
        }">
            <!-- <div :class="isMobile ? 'layout_mobile' : 'layout'" ref="layoutRef"> -->
            <!-- 头部 -->
            <cd-header></cd-header>
            <!-- 中间 -->
            <div :class="isMobile ? 'cd_middle_mobile' : 'cd_middle'">
                <router-view />
            </div>
            <!-- 尾部 -->
            <cd-footer @onFooter="onFooter"></cd-footer>
        </div>
    </el-scrollbar>
</template>
<!-- #c1c1c1 -->
<script lang="ts" setup>
import CdHeader from "./modules/CdHeader.vue";
import CdFooter from "./modules/CdFooter.vue";
// vue
import { nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";
// 状态值
import { common } from "@/store/modules/common";

// ============================== 定义变量开始 ============================== //
let isMobile = ref<Boolean>(false); //是否移动端
let zoom = ref<any>(1);
const router = useRouter();
const layoutRef = ref<any>(null);
const elScrollbarRef = ref<any>(null);
// ============================== 定义变量结束 ============================== //

// ============================== 事件开始 ============================== //
const onFooter = () => {
    nextTick(() => {
        elScrollbarRef.value.scrollTo({ top: 0 });
    });
}
// ============================== 事件结束 ============================== //

// ============================== 监听开始 ============================== //
// 监听当前浏览器是移动端还是PC端
watch(
    () => [common().isMobile, common().zoom],
    ([newIsMobile, newZoom]) => {
        isMobile.value = newIsMobile;
        console.log('newZoom', newZoom);
        zoom.value = newZoom;
    },
    { immediate: true }
);
// 监听路由变化，页面切换时滚动条回到顶部
watch(
    () => router.currentRoute.value,
    (newVal: any, oldVal: any) => {
        nextTick(() => {
            elScrollbarRef.value.scrollTo({ top: 0 });
        });
    },
    { immediate: true }
);

// ============================== 监听结束 ============================== //
</script>

<style scoped lang="scss">
.layout {
    background-color: #f5f5f5;
    min-width: 1226px;

    .cd_middle {
        width: 1226px;
        background-color: #fff;
        margin: 0 auto;
    }
}
</style>
<style lang="scss">
.el-scrollbar {
    .el-scrollbar__view {
        height: 100vh;
    }
}
</style>
