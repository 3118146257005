<template>
    <!-- <div id="cd_nav" :class="[isMobile ? 'cd_nav_mobile' : 'cd_nav_zh',]"> -->
    <div id="cd_nav" :class="{
        'cd_nav_mobile': isMobile,
        'cd_nav_zh': !isMobile,
        'cd_nav_en': !isMobile && languageIndex === 0,
        'cd_nav_ri': !isMobile && languageIndex === 1,
    }">
        <img class="logo" src="@/assets/images/pc/home/logo.png" alt="logo" v-if="!isMobile">
        <img class="logo" src="@/assets/images/mobile/home/logo.png" alt="logo" v-if="isMobile">
        <ul class="nav" v-if="!isMobile">
            <li class="item" :class="navIndex === index ? 'item_sel' : ''" v-for="(item, index) in navList"
                :key="index">
                <text class="nav_tab" @click="onSwitchNav(index)">{{ item.title }}</text>
            </li>
        </ul>
        <div class="language" v-if="isShowLan && isMobile">
            <div class="item" :class="languageIndex === index ? 'item_sel' : ''" v-for="(item, index) in languageList"
                :key="index" @click="onSwitchLanguage(index)">{{ item }}</div>
        </div>
        <div class="search" v-if="!isMobile">
            <!-- <el-input v-model="searchKey" :suffix-icon="Search" /> -->
        </div>
        <div class="nav" v-if="isMobile">
            <img class="nav_icon" src="@/assets/images/mobile/home/nav-icon.png" @click="onExpand">
        </div>
    </div>
</template>

<script lang="ts" setup>
// vue
import { reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
// element-plus
import { Search } from "@element-plus/icons-vue";
// 状态值
import { common } from "@/store/modules/common";

// ============================== 国际化开始 ============================== //
// 国际化
import { useI18n } from 'vue-i18n';
// 导入翻译文件
import enMessages from '@/i18n/en.json';
import zhMessages from '@/i18n/zh.json';
import riMessages from '@/i18n/ri.json';

// 创建国际化实例
const { t, locale } = useI18n({
    locale: "zh", // 设置默认语言
    messages: {
        en: enMessages,
        zh: zhMessages,
        ri: riMessages,
    },
});
const languageIndex = ref<any>(0)
watch(
    () => localStorage.getItem('languageIndex'),
    (newVal: any, oldVal: any) => {
        languageIndex.value = Number(newVal)
        locale.value = newVal == 2 ? 'zh' : newVal == 1 ? 'ri' : 'en'
    },
    { immediate: true }
);
// ============================== 国际化开始 ============================== //

// ============================== 传值开始 ============================== //
// 子向父传值
const emit = defineEmits<{
    // 打开菜单
    (event: "onExpand"): void;
}>();
// ============================== 传值结束 ============================== //

// ============================== 定义路由开始 ============================== //
const router = useRouter();
// 跳转路由
const onRouterPath = (index: Number) => {
    switch (index) {
        case 0:
            router.push({ name: "Home" });
            break;
        case 1:
            router.push({ name: "Products" });
            break;
        case 2:
            router.push({ name: "About", });
            break;
        case 3:
            router.push({ name: "News", });
            break;
        case 4:
            router.push({ name: "Advisory" });
            break;
    }
};
// 是否移动端
let isMobile = ref<Boolean>(false);
// ============================== 定义路由结束 ============================== //

// ============================== 定义字段开始 ============================== //
// nav
let navIndex = ref<Number>(0);
const navList = reactive<any>([
    {
        title: t('nav1'),
        children: [],
    },
    {
        title: t('nav2'),
        children: [],
    },
    {
        title: t('nav3'),
        children: [],
    },
    {
        title: t('nav4'),
        children: [],
    },
    {
        title: t('nav5'),
        children: [],
    },
]);
if (localStorage.getItem("navIndex")) {
    navIndex.value = Number(localStorage.getItem("navIndex"));
}
const languageList = reactive(["En", "RI", "中"]);
const isShowLan = ref<Boolean>(true)
// 搜索
let searchKey = ref<String>("");
// ============================== 定义字段结束 ============================== //

// ============================== 事件开始 ============================== //
const onSwitchNav = (index: any) => {
    localStorage.setItem("navIndex", JSON.stringify(index));
    localStorage.setItem("navChildIndex", JSON.stringify(0));
    common().setNavChildIndex(0)
    navIndex.value = index;
    onRouterPath(index);
};
const onExpand = () => {
    emit("onExpand");
};
// 切换语言
const changeLanguage = (val: any) => {
    locale.value = val
};
// 切换语言
const onSwitchLanguage = (index: Number) => {
    languageIndex.value = index;
    localStorage.setItem('languageIndex', JSON.stringify(index))
    switch (index) {
        case 0:
            localStorage.setItem('locale', 'en')
            break;
        case 1:
            localStorage.setItem('locale', 'ri')
            break;
        case 2:
            localStorage.setItem('locale', 'zh')
            break;
    }
    window.location.reload()
};
// ============================== 事件结束 ============================== //

// ============================== 监听开始 ============================== //
watch(
    () => [common().isMobile],
    ([newIsMobile]) => {
        isMobile.value = newIsMobile;
    },
    { immediate: true }
);
watch(
    () => [common().navIndex,],
    ([newNavIndex]) => {
        navIndex.value = Number(newNavIndex)
    },
    { immediate: true }
);
watch(
    () => router.currentRoute.value,
    (newVal: any, oldVal: any) => {
        if (newVal.path == "/news/detail") {
            isShowLan.value = false
        } else {
            isShowLan.value = true
        }
    },
    { immediate: true }
);
// ============================== 监听结束 ============================== //


// =========================== 暴露函数开始 =========================== //
defineExpose({
    changeLanguage,
});
// =========================== 暴露函数结束 =========================== //
</script>

<style scoped lang="scss">
.cd_nav_zh {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .logo {
        width: 220px;
        height: 42px;
    }

    .nav {
        flex: 1;
        text-align: end;

        .item {
            width: 155px;
            text-align: center;
            position: relative;
            list-style-type: none;
            display: inline-block;
            border-right: 1px solid #C9C9C9;

            .nav_tab {
                &:hover {
                    color: #363dbd;
                }
            }

            text {
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #555555;
                cursor: pointer;
            }

            &:last-child {
                margin-right: -10px;
                border: none;
            }
        }

        .item_sel {
            text {
                color: #363dbd;
            }
        }
    }

    .search {
        width: 227px;
        border-color: #FFF;
        // border: 1px solid #dcdcdc;
        padding: 2px;

        :deep(.el-input) {
            width: 227px;
            height: 32px;

            .el-input__wrapper {
                background: #dcdcdc;
                border-radius: 0px;
                border: none;
            }
        }
    }
}

.cd_nav_en {
    .nav {
        .item {
            &:nth-child(1) {
                width: 78px;
            }

            &:nth-child(2) {
                width: 169px;
            }

            &:nth-child(3) {
                width: 99px;
            }

            &:nth-child(4) {
                width: 203px;
            }

            &:nth-child(5) {
                width: 148px;
                padding-right: 23px;
            }
        }
    }
}

.cd_nav_ri {
    .nav {
        .item {
            &:nth-child(1) {
                width: 78px;
            }

            &:nth-child(2) {
                width: 159px;
            }

            &:nth-child(3) {
                width: 115px;
            }

            &:nth-child(4) {
                width: 189px;
            }

            &:nth-child(5) {
                width: 157px;
                padding-right: 29px;
            }
        }
    }
}
</style>
<style lang="scss">
.nav_popover {
    padding: 20px !important;

    .item_box {
        .box_item {
            margin-bottom: 22px;
            font-family: SourceHanSansCN-Regular;

            font-size: 14px;
            color: #555555;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                color: #363dbd;
            }
        }

        .box_item_sel {
            color: #0050ea;
        }
    }
}
</style>
