import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Layouts from "@/layouts/index.vue";
// 自定义方法
import { onIsMobile } from "@/utils/common";
const Home = () => import("@/views/home/index.vue");
const Products = () => import("@/views/products/index.vue");
const About = () => import("@/views/about/index.vue");
const News = () => import("@/views/news/index.vue");
const NewsDetail = () => import("@/views/news/components/CdNewsDetail.vue");
const NoticeDetail = () => import("@/views/news/components/CdNoticeDetail.vue");
const Advisory = () => import("@/views/advisory/index.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layouts,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: Home,
        name: "Home",
        meta: {
          title: "首页",
          affix: true,
        },
      },
      {
        path: "products",
        component: Products,
        name: "Products",
        meta: {
          title: "产品与服务",
          affix: true,
        },
      },
      {
        path: "about",
        component: About,
        name: "About",
        meta: {
          title: "关于我们",
          affix: true,
        },
      },
      {
        path: "news",
        component: News,
        name: "News",
        meta: {
          title: "新闻公告",
          affix: true,
        },
      },
      {
        path: "news/detail",
        component: NewsDetail,
        name: "newsDetail",
        meta: {
          title: "新闻详情",
          affix: true,
        },
      },
      {
        path: "notice/detail",
        component: NoticeDetail,
        name: "noticeDetail",
        meta: {
          title: "公告详情",
          affix: true,
        },
      },
      {
        path: "advisory",
        component: Advisory,
        name: "Advisory",
        meta: {
          title: "加盟咨询",
          affix: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
