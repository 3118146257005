/**
 * 设置缓存
 */
export const setStorage = (key: string, val: any) => {
  localStorage.setItem(key, val);
};
/**
 * 获取缓存
 */
export const getStorage = (key: string) => {
  return localStorage.getItem(key);
};
